<template>

  <van-popup v-model="orderPopupStatus" closeable position="bottom" :style="{ height: '100%' }"
    style="background-color: #f4f5f8">

    <van-nav-bar left-text="返回" left-arrow @click-left="orderPopupStatus = false" />

    <div class="createQrcode">

      <div class="list">
        <div class="item" v-for="(item, index) in cardsList" :key="index">
          <div class="no">卡密:{{ index + 1 }}</div>
          <div class="img">
            <div id="qrcode" ref="codeItem"></div>
          </div>
          <div class="pass">{{ item.cardNum }}</div>
        </div>
      </div>
    </div>

  </van-popup>
</template>
<script>
import { detail } from "@/api/order";
import QRCode from "qrcodejs2";

export default {
  props: {
    orderId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      orderPopupStatus: false,
      qrCodes: [],
      cardsList: [],
    };
  },

  created() { },
  methods: {
    /**
     * 执行二维码
     */
    hadldeOpen() {
      this.orderPopupStatus = true;
      this.queryOrderDetail();
    },

    /**,
     * 查询订单详情
     */
    queryOrderDetail() {
      //订单详情
      new Promise((resolve, rejust) => {
        let data = {
          id: this.orderId,
        };
        detail(data)
          .then((res) => {
            this.cardsList = res.result.cardsList;

            this.cardToQrCode(res.result.cardsList);

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 卡密转二维码
     */
    async cardToQrCode(cardsList) {

      this.$nextTick(() => {
        cardsList.forEach((item, index) => {
          this.$refs.codeItem[index].innerHTML = "";
          new QRCode(this.$refs.codeItem[index], {
            text: item.cardNum + item.cardPwd,
            width: 200,
            height: 200,
            render: 'table',
            colorDark: "#333333", //二维码颜色
            colorLight: "#ffffff", //二维码背景色
            correctLevel: QRCode.CorrectLevel.H //容错率，L/M/H
          })
        });
      }, 500)


      // setTimeout(() => {
      //   for (let i = 0; i < cardsList.length; i++) {
      //     try {
      //       // 创建二维码实例并设置参数
      //       new QRCode(this.$refs.qrCodeDiv, {
      //         text: cardsList[i].cardNum,
      //         width: 200,
      //         height: 200,
      //         colorDark: "#333333", // 二维码颜色
      //         colorLight: "#ffffff", // 二维码背景色
      //         correctLevel: QRCode.CorrectLevel.L, // 容错率，L/M/H
      //       });
      //     } catch (error) {
      //       console.error('Error generating QR Code:', error);
      //     }
      //   }
      // }, 100);

    },
  },
};
</script>

<style scoped>
.createQrcode .list {
  display: flex;
  padding: 11px;
  flex-direction: column;
  align-items: center;
}

.createQrcode .list .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 11px;
  width: 98%;
  background-color: #fff;
  justify-content: space-around;
  height: 331px;
  margin-bottom: 15px;
}
</style>
